var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ics-dialog-inner",
    {
      staticClass: "form-dialog",
      attrs: {
        loading: _vm.loading.submit,
        visible: _vm.uploadDialog.visible,
        title: _vm.uploadDialog.title,
        "submit-title": "导入",
        "cancel-title": "关闭",
        width: "50%"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.uploadDialog, "visible", $event)
        },
        submit: _vm.submit,
        close: _vm.close
      }
    },
    [
      _c(
        "ics-page-inner",
        { attrs: { title: "发票信息", "show-header": true } },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                model: _vm.formInfo,
                rules: _vm.rules,
                "label-width": "140px",
                "label-position": "left",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发票类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择发票类型",
                                filterable: ""
                              },
                              model: {
                                value: _vm.formInfo.invoiceKind,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInfo, "invoiceKind", $$v)
                                },
                                expression: "formInfo.invoiceKind"
                              }
                            },
                            _vm._l(_vm.constants.invoiceKindType, function(
                              item
                            ) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "upload-inner",
                  staticStyle: {
                    width: "90%",
                    top: "50%",
                    left: "50%",
                    "margin-left": "40px"
                  }
                },
                [
                  _c(
                    "fero-upload",
                    {
                      ref: "upload",
                      attrs: {
                        limit: _vm.limit,
                        action: _vm.uploadUrl,
                        name: "files",
                        headers: _vm.headers,
                        "file-list": _vm.formInfo.fileList,
                        drag: "",
                        accept: ".xlsx,.xls,.csv,.zip",
                        "auto-upload": false,
                        "before-upload": _vm.beforeUploadFile,
                        "on-success": _vm.onSuccessFile,
                        "on-preview": _vm.previewFile,
                        "on-error": _vm.onErrorFile,
                        "on-change": _vm.onChange,
                        "on-remove": _vm.onChange,
                        "on-exceed": _vm.onExceed
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")])
                      ]),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 请上传xlsx xls csv 和对应的zip ")
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c("el-form-item", { attrs: { label: "提示" } }, [
                        _c("p", [
                          _vm._v("点击下载发票导入模板《"),
                          _c(
                            "a",
                            { attrs: { href: "/template/发票模板.xlsx" } },
                            [_vm._v("发票导入模板")]
                          ),
                          _vm._v("》")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }