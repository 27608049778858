var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ics-dialog-inner",
    {
      staticClass: "form-dialog",
      attrs: {
        visible: _vm.invoiceDialog.visible,
        title: _vm.invoiceDialog.title,
        "show-submit": false,
        "show-cancel": !_vm.showButton,
        "cancel-title": "关闭",
        width: "60%"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.invoiceDialog, "visible", $event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "form-area",
          staticStyle: {
            width: "100%",
            height: "380px",
            overflow: "auto",
            "overflow-x": "hidden"
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-inner" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.invoiceDetail, "label-width": "120px" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 80 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发票类型：" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.statusFormat(
                                      _vm.invoiceDetail.invoiceType,
                                      "invoiceType"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发票号码：" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.invoiceDetail.invoiceNumber
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发票日期：" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.invoiceDetail.billingDate
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发票代码：" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.invoiceDetail.invoiceCode
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-area" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 80 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "area-title" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(" 购买方 ")
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-inner" },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 80 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "名称：" } },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .buyerTaxName
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "纳税人识别号：" }
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .buyerTaxNumber
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地址、电话：" } },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .buyerAddressPhone
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "开户行及账号：" }
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .buyerBankAccount
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "area-title" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(" 销售方 ")
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-inner" },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 80 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "名称：" } },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .sellerTaxName
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "纳税人识别号：" }
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .sellerTaxNumber
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地址、电话：" } },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .sellerAddressPhone
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "开户行及账号：" }
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.invoiceDetail
                                                      .sellerBankAccount
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.invoiceDetail.invoiceDetails, function(
                    item,
                    index
                  ) {
                    return _c("div", { key: index, staticClass: "form-area" }, [
                      _c("div", { staticClass: "area-title" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(" 货物或应税劳务、服务名称 ")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-inner" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 80 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "规格型号：" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              item.model
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "单位：" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              item.units
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "数量：" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              item.quantity
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "单价：" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              item.unitPrice
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "税率：" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              item.taxRate
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "税额：" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              item.tax
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  }),
                  _c("div", { staticClass: "form-area" }, [
                    _c(
                      "div",
                      { staticClass: "form-inner" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 80 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "价税合计(大写）：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.totalAmountStr
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "价税合计(小写）：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.totalAmount
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "合计金额：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.totalAmountTax
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "合计税额：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.totalTax
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-area" }, [
                    _c(
                      "div",
                      { staticClass: "form-inner" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 80 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "收款人：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.receiptName
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "复核：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.reviewerName
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "开票人：" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.invoiceDetail.operatorName
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm.showButton
        ? _c(
            "template",
            { slot: "btn-behind" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    padding: "0px 0px 20px",
                    margin: "0 auto",
                    "text-align": "center",
                    "margin-top": "20px"
                  },
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "add-btn", on: { click: _vm.close } },
                    [_vm._v(" 信息有误，重新识别 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "add-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" 确认无误 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }